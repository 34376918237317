export const fetchTripAdvanceURL = () => {
  return '/finance/advance-search-trip';
};

export const updateTripChargeURL = () => {
  return '/finance/save-trip-charge';
};

export const verifyOTPURL = () => {
  return '/finance/verify-trip-charge-otp';
};

export const sendOTPURL = () => {
  return '/finance/send-trip-charge-otp';
};

export const addAdvanceURL = () => {
  return '/finance/add-new-advance';
};

export const fetchTripChargesURL = () => {
  return '/finance/tripcharge-checklist';
};

export const fetchRequestStatusURL = (search) => {
  // return `/finance/advance-requests${search ? '?' + search : ''}`;
  return `/finance/advance-requests${search ? search : ''}`;
};

export const updateRequestStatusURL = () => {
  return '/finance/advance-request-action';
};

export const tripAdvanceHistoryURL = (tripCode) => {
  return `/finance/trip-advance-history/${tripCode}`;
};

export const tripChagresByCodeURL = (tripCode) => {
  return `/finance/trip-charge-details/${tripCode}`;
};

export const fetchPrevTripChargesURL = (tripCode) => {
  return `/finance/trip-revenue-details/${tripCode}`;
};

export const fetchUploadFinanceURL = () => {
  return `/finance/upload-other-payments`;
};

export const uploadFinanceURL = () => {
  return `finance/upload-other-payments`;
};

export const closeRequestURL = () => {
  return `finance/close-diesel-request`;
};

export const addBeneficiaryURL = () => {
  return '/finance/add-beneficiary';
};

export const fetchAdvancePaymentHistoryURL = (tripId, vendorID) => {
  return `/finance/trip-advance-history?trip_id=${tripId}&vendor_id=${vendorID}`;
};
